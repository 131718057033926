import React from 'react'
import '../App.js'
import './HeroSection.css'

function HeroSection() {
  return (
    <div className='hero-container'>
      <h1>
        SOFTWARE ENGINEER 
      </h1>
      <p>Lets Change the World Together</p>
    </div>
  )
}

export default HeroSection;