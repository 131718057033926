import React from 'react'
import '../../App.css'

export default function Projects() {
  return (
    <div>
      <h1 className='Portfolio'>Portfolio</h1>
    </div>
  )
}
