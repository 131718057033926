import '../../App.js';
import Cards from '../Cards.js';
import HeroSection from '../HeroSection'

function Home () {
  return (
    <>
      <HeroSection />
      <Cards />
    </>
  )
}

export default Home;